import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookSquare } from "@fortawesome/free-brands-svg-icons";
import Footer from ".././Components/footer";
import CookieConsent from "react-cookie-consent";
import { Helmet } from "react-helmet-async";

function Home() {
  const listyle = {
    "list-style-type": "none",
    "text-align": "center",
    "font-size": "20px",
    padding: "8px",
    textAlign: "left",
  };

  const repastyle = {
    width: "80%",
    padding: "5px",
  };
  const li1style = {
    listStyleType: "none",
    fontSize: "25px",
  };
  // const imstyle = {
  //   width: "5%",
  // };
  const teststyle = {
    display: "flex",
    flexDiection: "row",
    justifyContent: "space-between",
  };
  const applestyle = {
    width: "18%",
    padding: "15px",
  };
  const xiaomistyle = {
    width: "18%",
    padding: "15px",
  };
  const samsungstyle = {
    width: "20%",
    padding: "15px",
  };

  const hstyle = {
    fontSize: "25px",
  };

  const divborderstyle = {
    border: "1px solid black",
    borderRadius: "10px",
  };
  const fontstyle = {
    fontSize: "30px",
  };

  const spanstyle = {
    fontStyle: "italic",
    fontSize: "15px",
  };

  const pricestyle = {
    fontWeight: "bold",
    alignItems: "center",
  };

  const annoncestyle = {
    display: "flex",
    flexDiection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "1px !important",
  };
  const travstyle = {
    width: "70%",
    padding: "20px",
    boxShadow:
      " rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px",
  };

  return (
    <div class="container mt-4 mb-5">
      <Helmet>
        <meta
          name="description"
          content="Diagnostic et réparation de Mobile à la Riviere saint-louis 97421."
        ></meta>
        <title>Mobile Service</title>
      </Helmet>
      <CookieConsent
        enableDeclineButton
        onDecline={() => {
          alert("C'est noté!");
        }}
        location="bottom"
        buttonText="accepter"
        declineButtonText="refuser"
        cookieName="myAwesomeCookieName2"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
      >
        Ce site utilise des coockies pour une meilleure expérience de
        navigation.
      </CookieConsent>

      <h1 class="mt-3 mb-4">Réparation de téléphones mobile</h1>
      <div class="row">
        <div class="col-sm">
          <img
            src="./src/../Images/repare.jpg"
            alt="reparateur"
            style={repastyle}
          />
        </div>
        <div class="col-sm">
          <div></div>
          <div class="mt-4">
            <ul class="mt-4">
              <li style={li1style}>Nos tarifs:</li>
              <div style={annoncestyle}>
                <li style={listyle}>
                  Diagnostic de votre mobile{" "}
                  {/* <span style={spanstyle}>à partir de </span>{" "} */}
                </li>
                <p style={pricestyle}>30 €</p>
              </div>

              <div style={annoncestyle}>
                <li style={listyle}>
                  Changement de batterie{" "}
                  <span style={spanstyle}>à partir de </span>{" "}
                </li>

                <p style={pricestyle}>30€</p>
              </div>

              <div style={annoncestyle}>
                <li style={listyle}>
                  Remplacement d'ecran cassé{" "}
                  <span style={spanstyle}>à partir de </span>
                </li>

                <p style={pricestyle}>129€</p>
              </div>

              <div style={annoncestyle}>
                <li style={listyle}>
                  Deblocage compte google{" "}
                  {/* <span style={spanstyle}>à partir de </span>{" "} */}
                </li>

                <p style={pricestyle}>40€</p>
              </div>

              <div style={annoncestyle}>
                <li style={listyle}>
                  Réinstallation windows{" "}
                  {/* <span style={spanstyle}>à partir de </span>{" "} */}
                </li>

                <p style={pricestyle}>40€</p>
              </div>
            </ul>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm mt-3">
          <h3>Gabriel FONTAINE</h3>
          <h3>06 12 34 56 78</h3>
          <h3>gabriel@mobileservice.fr</h3>
          <FontAwesomeIcon icon={faFacebookSquare} style={fontstyle} />
          <i class="faBrandsFaFacebook"></i>
        </div>

        <div class="col-sm-5 mt-3" style={divborderstyle}>
          <div class="mt-2" style={hstyle}>
            Réparation de toutes marques dont:
          </div>
          <div style={teststyle}>
            <img
              style={applestyle}
              src="./src/../Images/apple.png"
              alt="apple"
            />
            <img
              style={xiaomistyle}
              src="./src/../Images/xiaomi.png"
              alt="xiaomi"
            />
            <img
              style={samsungstyle}
              src="./src/../Images/samsung.png"
              alt="samsung"
            />
          </div>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-sm mb-5 ">
          <img
            style={travstyle}
            src="./src/../Images/1.jpg"
            alt="Notre atelier"
          />
        </div>
        <div class="col-sm mb-5">
          <img
            style={travstyle}
            src="./src/../Images/2.jpg"
            alt="Notre atelier"
          />
        </div>
        <div class="col-sm mb-5">
          <img
            style={travstyle}
            src="./src/../Images/3.jpg"
            alt="Notre atelier"
          />
        </div>
        <div class="col-sm mb-5">
          <img
            style={travstyle}
            src="./src/../Images/4.jpg"
            alt="Notre atelier"
          />
        </div>
        <div class="col-sm mb-5">
          <img
            style={travstyle}
            src="./src/../Images/5.jpg"
            alt="Notre atelier"
          />
        </div>
      </div>
      <div class="row">
        <Footer />
      </div>
    </div>
  );
}
export default Home;
