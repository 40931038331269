import React from "react";

function About() {
  const abouthstyle = {
    textAlign: "center",
    fontWeight: "bold",
  };

  const abouth5style = {
    fontWeight: "bold",
  };

  return (
    <div class="container mt-2">
      <div>
        <h1 class="mt-4" style={abouthstyle}>
          CGU / CGV
        </h1>
      </div>

      <div class="">
        <h5 class="mt-4" syle={abouth5style}>
          ÉDITION DU SITE
        </h5>
        <p>
          Propriétaire du site : laurentfontaine-re.fr - Contact :
          laurentfontaine-re.fr@test.fr L'hébergeur du site
          laurentfontaine-re.frest la société LIGNE WEB SERVICES, dont le siège
          social est situé au 10, RUE DE PENTHIEVRE - 75008 PARIS, avec le
          numéro de téléphone : +33 (0)1 77 62 30 03. Hébergeur : AutreLWS 10,
          RUE DE PENTHIEVRE - 75008 PARIS +33 (0)1 77 62 30 03. Délégué à la
          protection des données : laurentfontaine-re.fr -
          aurentfontaine-re.fr@test.fr
        </p>
      </div>
      <div class="">
        <h5 class="mt-4" syle={abouth5style}>
          OBJET DU SITE ET PRESTATIONS
        </h5>
        <p>
          Le site présente les prestations que nous effectuons.Les prix des
          prestations sont indiquées "à partir de ".Le prix définitif dépend des
          prestations et réparations effectuées.Un diagnostic sera éffectué lors
          du dépot de votre appareil, et un accompte de 40% devra etre réglé
          avant réparation . Si le client refuse le devis, un forfait de 30€
          sera facturé.
        </p>
      </div>
      <div class="">
        <h5 class="mt-4" syle={abouth5style}>
          LIMITATIONS DE RESPONSABILITÉ.
        </h5>
        <p>
          laurentfontaine-re.fr ne pourra être tenu pour responsable des
          dommages directs et indirects causés au matériel de l’utilisateur,
          lors de l’accès au site laurentfontaine-re.fr. laurentfontaine-re.fr
          décline toute responsabilité quant à l’utilisation qui pourrait être
          faite des informations et contenus présents sur laurentfontaine-re.fr.
          laurentfontaine-re.fr s’engage à sécuriser au mieux le site
          laurentfontaine-re.fr, cependant sa responsabilité ne pourra être mise
          en cause si des données indésirables sont importées et installées sur
          son site à son insu. Des espaces interactifs (espace contact ou
          commentaires) sont à la disposition des utilisateurs.
          laurentfontaine-re.fr se réserve le droit de supprimer, sans mise en
          demeure préalable, tout contenu déposé dans cet espace qui
          contreviendrait à la législation applicable en France, en particulier
          aux dispositions relatives à la protection des données. Le cas
          échéant, laurentfontaine-re.fr se réserve également la possibilité de
          mettre en cause la responsabilité civile et/ou pénale de
          l’utilisateur, notamment en cas de message à caractère raciste,
          injurieux, diffamant, ou pornographique, quel que soit le support
          utilisé (texte, photographie …).
        </p>
      </div>

      <div class="">
        <h5 class="mt-4" syle={abouth5style}>
          CNIL ET GESTION DES DONNÉES PERSONNELLES.
        </h5>
        <p>
          Conformément aux dispositions de la loi 78-17 du 6 janvier 1978
          modifiée, l’utilisateur du site laurentfontaine-re.fr dispose d’un
          droit d’accès, de modification et de suppression des informations
          collectées. Pour exercer ce droit, envoyez un message à notre Délégué
          à la Protection des Données : laurentfontaine-re.fr -
          laurentfontaine-re.fr@test.fr. Pour plus d'informations sur la façon
          dont nous traitons vos données (type de données, finalité,
          destinataire...), lisez notre Politique de Confidentialité. Il est
          également possible de déposer une réclamation auprès de la CNIL. Il
          est rappelé conformément à l’article L122-5 du Code de propriété
          intellectuelle que l’Utilisateur qui reproduit, copie ou publie le
          contenu protégé doit citer l’auteur et sa source.
        </p>
      </div>

      <div class="">
        <h5 class="mt-4" syle={abouth5style}>
          LIENS HYPERTEXTES ET COOKIES
        </h5>
        <p>
          Le site laurentfontaine-re.fr contient des liens hypertextes vers
          d’autres sites et dégage toute responsabilité à propos de ces liens
          externes ou des liens créés par d’autres sites vers
          laurentfontaine-re.fr. La navigation sur le site laurentfontaine-re.fr
          est susceptible de provoquer l’installation de cookie(s) sur
          l’ordinateur de l’utilisateur. Un "cookie" est un fichier de petite
          taille qui enregistre des informations relatives à la navigation d’un
          utilisateur sur un site. Les données ainsi obtenues permettent
          d'obtenir des mesures de fréquentation, par exemple. Vous avez la
          possibilité d’accepter ou de refuser les cookies en modifiant les
          paramètres de votre navigateur. Aucun cookie ne sera déposé sans votre
          consentement. Les cookies sont enregistrés pour une durée maximale de
          6 mois. Pour plus d'informations sur la façon dont nous faisons usage
          des cookies, lisez notre Politique de Confidentialité. La
          responsabilité du site ne peut être engagée en cas de force majeure ou
          du fait imprévisible et insurmontable d'un tiers.
        </p>
      </div>

      <div class="">
        <h5 class="mt-4" syle={abouth5style}>
          DROIT APPLICABLE ET ATTRIBUTION DE JURIDICTION.
        </h5>
        <p>
          Tout litige en relation avec l’utilisation du site
          laurentfontaine-re.fr est soumis au droit français. En dehors des cas
          où la loi ne le permet pas, il est fait attribution exclusive de
          juridiction aux tribunaux compétents de Saint-Pierre.
        </p>
      </div>
    </div>
  );
}
export default About;
