import React from "react";
import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import * as emailjs from "emailjs-com";

import Footer from ".././Components/footer";

const Wstyle = {
  width: "85%",
  "border-radius": "15px",
};

const Pstyle = {
  fontSize: "25px",
};

const inputstyle = {
  borderRadius: "10px",
};

const output_message_success = {
  textAlign: "center",
  color: "green",
  fontSize: "20px",
  fontWeight: "bold",
  display: "none",
};

const output_message_error = {
  textAlign: "center",
  color: "red",
  fontSize: "20px",
  fontWeight: "bold",
  display: "none",
};
function success() {
  document.getElementById("success").style.display = "block";
}
function error() {
  document.getElementById("error").style.display = "block";
}

const Contact = () => {
  const [buttonState, setButtonState] = useState("Send Message");

  const formik = useFormik({
    //we have created our initailValues object in a format EmailJS accepts
    initialValues: {
      email: "",
      phoneNumber: "",
      message: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("*Adresse mail invalide")
        .required("* Ce champs est requis"),
      phoneNumber: Yup.string().required("* Ce champs est requis"),
      message: Yup.string().required("* Ce champs est requis"),
    }),
    onSubmit: (values, { setSubmitting, resetForm }) => {
      try {
        emailjs
          .send(
            process.env.REACT_APP_SERVICE_EMAILJS,
            process.env.REACT_APP_TEMPLATE_EMAILJS,
            values,
            process.env.REACT_APP_USER_EMAILJS
          )
          .then(() => {
            setButtonState("Send Email");
            setSubmitting(false);
            resetForm();
            success();

            // window.location.href = "/";
          });
      } catch {
        setSubmitting(false);
        error();

        // window.location.href = "/";
      }
    },
  });

  return (
    <div class="container mt-4 mb-5">
      <h1>Nous contacter</h1>
      <p style={Pstyle}>
        Décrivez-nous votre problème, laissez vos coordonnées ...Nous vous
        contacterons rapidement !
      </p>
      <div class="row mt-4">
        <div class="col-sm">
          <img style={Wstyle} src="./src/../Images/log.jpg" alt="reparateur" />
        </div>
        <div class="col-sm">
          <form className="formcontact" onSubmit={formik.handleSubmit}>
            <div class="container mt-3">
              <h5>Votre mail</h5>
              <input
                type="email"
                name="email"
                label="email"
                onChange={formik.handleChange}
                value={formik.values.email}
                style={inputstyle}
              />
            </div>
            <div
              className={`expandable ${
                formik.touched.email && formik.errors.email ? "show" : ""
              }`}
            >
              {formik.errors.email}
            </div>
            <div class="container mt-3">
              <h5>Votre numéro de téléphone</h5>
              <input
                type="text"
                name="phoneNumber"
                label="phoneNumber"
                onChange={formik.handleChange}
                value={formik.values.phoneNumber}
                style={inputstyle}
              />
              <div
                className={`expandable ${
                  formik.touched.phoneNumber && formik.errors.phoneNumber
                    ? "show"
                    : ""
                }`}
              >
                {formik.errors.phoneNumber}
              </div>
            </div>

            <div class="container mt-3">
              <h5>Votre message</h5>
              <textarea
                rows="5"
                cols="60"
                name="message"
                label="message"
                onChange={formik.handleChange}
                value={formik.values.message}
                style={inputstyle}
              />
            </div>
            <div
              className={`expandable ${
                formik.touched.message && formik.errors.message ? "show" : ""
              }`}
            >
              {formik.errors.message}
            </div>
            <button
              class="btn btn-dark mt-3"
              type="submit"
              disabled={formik.isSubmitting}
            >
              <span>{buttonState}</span>
            </button>
          </form>
        </div>
      </div>
      <div class="row mt-5">
        <div style={output_message_success} id="success">
          Votre message a été envoyé avec Succés
        </div>
        <div style={output_message_error} id="error">
          Votre message n'a pas pu etre envoyé
        </div>
      </div>

      <div class="row mt-5">
        <Footer />
      </div>
    </div>
  );
};
export default Contact;
