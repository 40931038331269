import React from "react";
import { Link } from "react-router-dom";

const footerstyle = {
  textDecoration: "none",
  fontSize: "18px",
  color: "black",
};

function Footer() {
  return (
    <div class="container">
      <Link
        to="https://www.laurentfontaine-re.fr/"
        target="_blank"
        style={footerstyle}
      >
        Site developpé par Laurent FONTAINE
      </Link>
    </div>
  );
}
export default Footer;
