import Navbar from "./Components/navbar";
import Home from "./Components/home";
import Contact from "./Components/contact";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import About from "./Components/apropos";
import emailjs from "emailjs-com";
import { HelmetProvider } from "react-helmet-async";
function App() {
  const helmetContext = {};
  document.body.style = "background: #A1CCD1;";
  return (
    <div className="App">
      <head>
        <meta charset="UTF-8"></meta>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0"
        ></meta>
        <script
          type="text/javascript"
          src="https://cdn.jsdelivr.net/npm/emailjs-com@2/dist/email.min.js"
        ></script>
        <script type="text/javascript">
          (function() {emailjs.init(process.env.REACT_APP_USER_EMAILJS)})();
        </script>
      </head>
      <HelmetProvider context={helmetContext}>
        <Navbar />
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="A_propos" element={<About />} />
            <Route path="Contact" element={<Contact />} />
          </Routes>
        </BrowserRouter>
      </HelmetProvider>
    </div>
  );
}

export default App;
